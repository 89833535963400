// import React from 'react';
// import '../../CSS/product/Product.css';


// const Product = () => {
//   return (
//     <div className="productpage">
//         <h1 className='productpage'>Our Product Suite</h1>
//          <div className="productpage-main-container">
//     <h1 className='productpage-main-heading' >Online Assessment Application</h1>
//      <div className="productpage-container">
//       <img
//         src={`${process.env.PUBLIC_URL}/Images/online.png`}
//         alt="Assessment Application"
//         className="productpage-image"
//       />
//       <div className="productpage-content">
//         <p className='productpage-content-para'>
//           Our Online Assessment Application is a cutting-edge platform tailored for both students and recruiters, designed to streamline the initial interview process. With this application, recruiters can conduct skill-based assessments, while students gain valuable practice through real-world scenarios.
//         </p>
//         <h3 className='productpage-features'>Key Features:</h3>
//         <ul className='productpage-ul' >
//           <li className='productpage-list'><strong>Skill-Based Assessments:</strong> Customizable tests and assignments to match specific skill requirements.</li>
//           <li className='productpage-list'><strong>Real-Time Analytics:</strong> Get instant feedback on performance, with detailed insights to help recruiters make data-driven decisions.</li>
//           <li className='productpage-list'><strong>Seamless Integration:</strong> Easy integration with other recruitment tools, allowing a smoother workflow.</li>
//           <li className='productpage-list'><strong>User-Friendly Interface:</strong> A clean, intuitive design for ease of use, making it accessible for all users.</li>
//         </ul>
       
//       </div>
//     </div>
//      <p className='productpage-overview'>
//           ** This platform is ideal for universities, recruitment agencies, and companies looking to simplify and elevate the hiring process, especially for the initial stages of candidate evaluation .**
//         </p>
//    </div>
 
//     </div>
  
//   );
// }

// export default Product;


import React from 'react';
import '../../CSS/product/Product.css';
import products from './ProductData'; // Assuming ProductData.js is in the same folder

const Product = () => {
  return (
    <div className="product-page">
      <h1 className="product-page">Our Product Suite</h1>
      <div className="product-page-main-container">
        {products.map((product, index) => (
          <div className='product-page-box'>
              <h1 className="product-page-main-heading">{product.title}</h1>
              <div key={index} className="product-page-container">
          
          <img
            src={product.image}
            alt={product.title}
            className="product-page-image"
          />
          <div className="product-page-content">
            <p className="product-page-content-para">{product.description}</p>
            <h3 className="product-page-features">Key Features:</h3>
            <ul className="product-page-ul">
              {product.features.map((feature, index) => (
                <li key={index} className="product-page-list">
                  <span className="feature-icon">{feature.icon}</span>
                  <strong>{feature.title}:</strong> {feature.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <p className="product-page-overview">{product.onlinedes}</p>

          </div>
        
        ))}
      </div>
    </div>
  );
};

export default Product;




// import React from "react";
// import ProductData from '../product/ProductData'

// const Product = () => {
//   return(
//     <div className="productpage-main-container">
//       <div className="productpage-container">
//         <div className="productpage-wrapper">

//         </div>
//       </div>
//     </div>
//   )
// }

// export default Product


// import React from "react";
// import ProductData from '../product/ProductData';
// import '../../CSS/product/Product.css';

// const Product = () => {
//   return (
//     <div className="productpage-main-container">
//       <h1 className='productpage'>Our Product Suite</h1>
//       <div className="productpage-container">
//         <div className="productpage-wrapper">
//           {ProductData.map((product, index) => (
//             <div key={index} className="product-item">
//               {/* Product Title */}
//               <h2 className="product-title">{product.title}</h2>
              
//               {/* Product Description */}
//               <p className="product-description">{product.description}</p>
              
//               {/* Product Image */}
//               <div className="product-image">
//                 <img src={product.image} alt={product.title} />
//               </div>
              
//               {/* Product Features */}
//               <div className="product-features">
//                 {product.features.map((feature, idx) => (
//                   <div key={idx} className="feature-item">
//                     <div className="feature-icon">{feature.icon}</div>
//                     <p  className="feature-description"><span className="feature-title">{feature.title} :</span> {feature.description}</p>
//                     {/* <p className="feature-description">{feature.description}</p> */}
//                   </div>
//                 ))}
//               </div>
              
//               {/* Product Online Description */}
//               <p className="product-onlinedes">{product.onlinedes}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Product;





