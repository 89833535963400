import React from 'react'
import About from './About'
import Core from './Core'
import Discover from './Discover'
import MissionVision from './MissionVision'

export default function Aboutus() {
  return (
    <div>
      <About />
      <MissionVision />
      <Discover />
      <Core /> 
    </div>
  )
}
