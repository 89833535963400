// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// function PageNotFound() {
//   const navigate = useNavigate();

//   const handleRedirect = () => {
//     navigate('/');
//   };

//   return (
//     <div className="pagenotfound-main-container" style={styles.mainContainer}>
//       <div className="pagenotfound-container" style={styles.container}>
//         <div className="pagenotfound-wrapper" style={styles.wrapper}>
//           <div className="pagenotfound-img-section" style={styles.imgSection}>
//             <img
//               src="../Images/pagenotfound-3-images.webp"  // Replace with your 404 image URL
//               alt="404"
//               style={styles.image}
//             />
//           </div>
//           <div className="pagenotfound-content-section" style={styles.contentSection}>
//             <h1 style={styles.title}>404</h1>
//             <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
//             <button onClick={handleRedirect} style={styles.button}>
//               Go Back to Home
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// const styles = {
//   mainContainer: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f9fc',
//     fontFamily: 'Arial, sans-serif',
//   },
//   container: {
//     textAlign: 'center',
//   },
//   wrapper: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     maxWidth: '600px',
//     padding: '20px',
//     backgroundColor: '#ffffff',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     borderRadius: '8px',
//   },
//   imgSection: {
//     marginBottom: '20px',
//   },
//   image: {
//     width: '200px',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   contentSection: {
//     textAlign: 'center',
//   },
//   title: {
//     fontSize: '72px',
//     color: '#ff540b',
//     margin: '0',
//   },
//   message: {
//     fontSize: '18px',
//     color: '#333',
//     margin: '20px 0',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '16px',
//     color: '#ffffff',
//     backgroundColor: '#ff540b',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
// };

// export default PageNotFound;





import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Home/PageNotFound.css'; // Import CSS file for styling

function PageNotFound() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div className="pagenotfound-main-container">
      <div className="pagenotfound-container">
        <div className="pagenotfound-wrapper">
          <div className="pagenotfound-img-section">
            <img
              src="../Images/error-404.webp"  // Replace with your 404 image URL
              alt="404"
              className="pagenotfound-image"
            />
          </div>
          <div className="pagenotfound-content-section">
            <h2 className="pagenotfound-title">Page Not Found</h2>
            <p className="pagenotfound-message">Oops! The page you're looking for doesn't exist.</p>
            <button onClick={handleRedirect} className="pagenotfound-button">
              Go Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
