import React from 'react';
import Homevdo from './Homevdo';
import DigitalInnovation from './DigitalInnovation';
import HomeOurServices from './Home-OurServices';
import HomeWhyChoose from './Home-Why-Choose';
import MissionStatement from './Home-mission';
import HomeContactSection from './Home-lastpage';


export default function Home() {
  return (
    <div>
      <Homevdo />  
      <DigitalInnovation />
      <HomeOurServices />
      <HomeWhyChoose />
      <MissionStatement />
      <HomeContactSection />
      
    </div>
  )
}
