import React, { useState } from "react";
import "../../../CSS/Services/MobApp/MobileHome.css";

function MobileHome() {
  const [showMoreSecond, setShowMoreSecond] = useState(false);
  const [showMoreThird, setShowMoreThird] = useState(false);

  const handleToggleSecond = () => {
    setShowMoreSecond(!showMoreSecond);
  };

  const handleToggleThird = () => {
    setShowMoreThird(!showMoreThird);
  };

  return (
    <div className="mobdev-content">
      <div className="mobimage">
          <h2 className="mobdev-h2">Unlock tailored Android experiences with our custom app development services</h2>
          <img src="../Images/mobapp1.jpg" alt="mobile app development" id="mobimg" />
          <div className="mobcontent">
          <p>
            Looking to integrate cutting-edge Android app solutions into your business? Choose theLogicalMonks for unparalleled expertise in leveraging the latest Android technologies. We are your trusted partner for delivering robust and scalable mobile apps that drive business success.
          </p>
          {showMoreSecond ? (
            <p >
              Our tailored Android solutions are designed to meet your specific needs, providing a seamless integration
              process and delivering tangible profitability for your business. Whether you're a startup or an enterprise,
              our team is dedicated to providing industrial-grade experiences that elevate your mobile presence.{" "}
              <button onClick={handleToggleSecond} className="mobilehome-btn">Read Less</button>
            </p>
          ) : (
            <p >
              Our tailored Android solutions are designed to meet your specific needs, providing a seamless integration
              process and delivering tangible profitability for your business.{" "}
              <button onClick={handleToggleSecond} className="mobilehome-btn">Read More</button>
            </p>
          )}
          {showMoreThird ? (
            <p>
              At our Company our Android app development process is meticulously crafted to transform your ideas into
              innovative and user-centric applications. It begins with a deep understanding of your vision, progresses
              through strategic roadmap creation, and culminates in rigorous testing and successful launch. As a team of
              skilled Android developers, we are committed to delivering applications that are not just functional but
              also intuitive and tailored to your specific needs.{" "}
              <button onClick={handleToggleThird} className="mobilehome-btn">Read Less</button>
            </p>
          ) : (
            <p >
              At our Company our Android app development process is meticulously crafted to transform your ideas into
              innovative and user-centric applications. It begins with a deep understanding of your vision, progresses
              through strategic roadmap creation, and culminates in rigorous testing and successful launch.{" "}
              <button onClick={handleToggleThird} className="mobilehome-btn">Read More</button>
            </p>
          )}
          </div>
        </div>
      </div>

  );
}

export default MobileHome;