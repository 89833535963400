import React from 'react';
import '../../CSS/Home/Home-lastpage.css';

const HomeContactSection = () => {
  return (
    <div className="contact-section">
      <img
        src={`${process.env.PUBLIC_URL}/Images/digital-vision-home2.jpg`}
        alt="Digital Vision"
        className="background-image"
      />
      <div className="contact-card">
        <div className="contact-content">
          <p className="contact-description">
            Let’s Bring Your Digital Vision to Life. Contact us today to discuss how we can help transform your business!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeContactSection;
