
// import React from 'react';
// import { Link ,useNavigate ,useLocation} from 'react-router-dom'; // Import Link from React Router
// import '../../CSS/Home/Footer.css'; // Ensure the CSS file is in the correct path
// import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
// // import logoImage from '../images/logomain.png'; 


// const Footer = ({ setActiveLink }) => {
//   const navigate = useNavigate();
//   const location = useLocation(); // Hook to get the current route

//   // Define routes where footer should not be displayed
//   const hideFooterRoutes = ['/signup-form', '/login-form' ,'/quote-form'];

//   // Check if the current route is in the hideFooterRoutes array
//   if (hideFooterRoutes.includes(location.pathname)) {
//     return null; // Don't render the footer
//   }

//   const handleLogoClick = () => {
//     setActiveLink('overview'); // Set home link as active
//     navigate('/'); // Redirect to home page
//   };

//   return (
//     <footer className="footer">
//       <div className="footer-content">
//         <div className="footer-section">
//           {/* <img
//             src={logoImage}
//             alt="Autopro Logo"
//             className="footer-logo"
//             onClick={handleLogoClick}
//           /> */}
//         </div>

//         <div className="footer-section">
//           <h2 className="footer-subtitle">Quick Links</h2>
//           <div className="footer-links">
//             <Link to="/" className="footer-link" >
//               Home <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/extended-warranty" className="footer-link" onClick={() => setActiveLink('extended-warranty')}>
//               Extended Warranty <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/value-care" className="footer-link" onClick={() => setActiveLink('value-care')}>
//               Value Care <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/product" className="footer-link" onClick={() => setActiveLink('product')}>
//               Product <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/brands" className="footer-link" onClick={() => setActiveLink('brands')}>
//               Brands <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/contact" className="footer-link" onClick={() => setActiveLink('contact')}>
//               Contact Us <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//           </div>
//         </div>

//         <div className="footer-section">
//           <h2 className="footer-subtitle">Social Media</h2>
//           <div className="footer-links">
//             <a href="https://www.facebook.com/Autoproextendedwarranty/" className="footer-link social-link"  target="_blank" rel="noopener noreferrer">
//               <i className="fab fa-facebook-f"></i> Facebook
//             </a>
//             <a href="https://x.com/AutoproWarranty" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
//             <i className="fa-solid fa-xmark"></i>
//            Twitter
//             </a>
//             <a href="https://www.instagram.com/autopro_warranty/?next=%2F" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
//               <i className="fab fa-instagram"></i> Instagram
//             </a>
//           </div>
//         </div>

//         <div className="footer-section">
//           <h2 className="footer-subtitle">Legal</h2>
//           <div className="footer-links">
//             <Link to="/terms-and-conditions" className="footer-link" >
//               Terms and Conditions <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/owner’s-responsibility" className="footer-link">
//               Owner’s Responsibility <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/general-exclusions" className="footer-link">
//               General Exclusions <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="footer-bottom">
//     <p className="footer-copyright">
//         &copy; Copyright {new Date().getFullYear()}. All rights reserved. AutoPro Warranty.
//     </p>
// </div>

//     </footer>
//   );
// };

// export default Footer;



// import React from 'react';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import '../../CSS/Home/Footer.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// const Footer = ({ setActiveLink }) => {

//   return (
//     <footer className="footer">
//       <div className="footer-content">
//         <div className="footer-section">
//           {/* Add logo image if required */}
//         </div>

//         <div className="footer-section">
//           <h2 className="footer-subtitle">Quick Links</h2>
//           <div className="footer-links">
//             <Link to="/" className="footer-link" >
//               Home <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/about" className="footer-link" onClick={() => setActiveLink('about')}>
//               About Us <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/services" className="footer-link" onClick={() => setActiveLink('services')}>
//               Services <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/product" className="footer-link" onClick={() => setActiveLink('product')}>
//               Product <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//             <Link to="/contact" className="footer-link" onClick={() => setActiveLink('contact')}>
//               Contact Us <i className="fas fa-arrow-right arrow-icon"></i>
//             </Link>
//           </div>
//         </div>

//         <div className="footer-section">
//           <h2 className="footer-subtitle">Social Media</h2>
//           <div className="footer-links">
//             <a href="https://www.facebook.com/profile.php?id=100090720567337" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
//               <i className="fab fa-facebook-f"></i> Facebook
//             </a>
//             <a href=" https://www.linkedin.com/company/93336726/admin/dashboard/" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
//               <i className="fab fa-twitter"></i> LinkedIn
//             </a>
//             <a href="https://www.instagram.com/thelogicalmonks/" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
//               <i className="fab fa-instagram"></i> Instagram
//             </a>
//           </div>
//         </div>

      
//       </div>

//       <div className="footer-bottom">
//         <p className="footer-copyright">
//           &copy; Copyright {new Date().getFullYear()}. All rights reserved. The Logical Monks.
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


import React from 'react';
import { Link } from 'react-router-dom';
import '../../CSS/Home/Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about ">
        <img src="../Images/logo1.png" alt="THE LOGICAL MONKS" id="logo" className='thelogicalmonks-logo' />
          {/* <h2 className="footer-subtitle">About Us</h2>
          <p>We are THE LOGICAL MONKS, dedicated to delivering quality services and solutions to our clients.</p> */}
        </div>

        <div className="footer-section">
          <h2 className="footer-subtitle">Quick Links</h2>
          <div className="footer-links">
            <Link to="/" className="footer-link" >
            Home <i className="fas fa-arrow-right arrow-icon"></i>
            </Link>
            <Link to="/about" className="footer-link" >
              About Us <i className="fas fa-arrow-right arrow-icon"></i>
            </Link>
            <Link to="/services" className="footer-link" >
              Services <i className="fas fa-arrow-right arrow-icon"></i>
            </Link>
            <Link to="/product" className="footer-link" >
              Product <i className="fas fa-arrow-right arrow-icon"></i>
            </Link>
            <Link to="/contact" className="footer-link" >
              Contact Us <i className="fas fa-arrow-right arrow-icon"></i>
            </Link>
          </div>
        </div>

        <div className="footer-section">
          <h2 className="footer-subtitle">Social Media</h2>
          <div className="footer-links">
            <a href="https://www.facebook.com/profile.php?id=100090720567337" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i> Facebook
            </a>
            <a href="https://www.linkedin.com/company/93336726/admin/dashboard/" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i> LinkedIn
            </a>
            <a href="https://www.instagram.com/thelogicalmonks/" className="footer-link social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i> Instagram
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
      &copy; Copyright {new Date().getFullYear()}. All rights reserved. The Logical Monks.
      </div>
    </footer>
  );
};

export default Footer;
