




import React from 'react';
import '../../CSS/Home/Home-OurServices.css';

const servicesData = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/digital-marketing-mobile.webp`,
    alt: "Digital Marketing",
    title: "Digital Marketing",
    description:
      "Harness the power of data-driven strategies to boost your brand’s visibility, engagement, and conversion. From social media management to SEO and targeted ad campaigns, we bring you measurable results with a personal touch.",
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/web-application-development.webp`,
    alt: "Web Application Development",
    title: "Web Application Development",
    description:
      "Our web applications are crafted with cutting-edge technology, intuitive user experiences, and robust functionality. Built with performance, security, and scalability in mind.",
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/website-development.webp`,
    alt: "Website Development",
    title: "Website Development",
    description:
      "We design visually appealing, responsive, and optimized websites that engage visitors and provide a seamless user experience, ensuring your site stands out in a crowded digital landscape.",
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/e-commerce-development.webp`,
    alt: "E-commerce Application Development",
    title: "E-commerce Application Development",
    description:
      "We create secure, user-friendly, and conversion-optimized e-commerce applications focused on user satisfaction and smooth transactions to drive repeat business.",
  },
];

const HomeOurServices = () => {
  return (
    <div className="home-services-container">
      <div className="home-services-wrapper">
        <div className="home-services-main-heading">Our Services</div>
        
        <div className="home-services-gallery">
          {servicesData.map((service, index) => (
            <div key={index} className="home-service-card">
              <img src={service.imgSrc} alt={service.alt} />
              <div className="home-service-card-content">
                <h3 className="home-service-heading">{service.title}</h3>
                <p className="home-services-para">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeOurServices;
