

import React from 'react';
import '../../CSS/Home/Home-Why-Choose.css';

const approachData = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/custer-center.webp`,
    alt: "Customer-Centric",
    title: "Customer-Centric Approach",
    description: "We put our clients first, focusing on understanding your business goals to deliver solutions that truly benefit you.",
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/end-to-end-solution.webp`,
    alt: "End-to-End Solutions",
    title: "End-to-End Solutions",
    description: "From initial planning to post-launch support, we’re with you every step of the way, providing reliable and scalable solutions.",
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/Images/innovation.webp`,
    alt: "Expertise & Innovation",
    title: "Expertise & Innovation",
    description: "Our team combines industry expertise with a passion for innovation, ensuring your business always benefits from the latest advancements.",
  },
];

const HomeWhyChoose = () => {
  return (
    <div className="approach-container">
      <div className="approach-wrapper">
        <h2 className="approach-main-heading">Our Approach</h2>
        
        <div className="approach-content">
          {approachData.map((item, index) => (
            <div key={index} className="approach-item">
              <img src={item.imgSrc} alt={item.alt} className="approach-icon" />
              <h3 className="approach-title">{item.title}</h3>
              <p className="approach-description">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeWhyChoose;
