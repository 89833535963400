import './App.css';
import { BrowserRouter as Router, Route,  Routes } from 'react-router-dom';
import Aboutus from './Components/AboutUs/Aboutus';
import Navbar from './Components/Home/Navbar';
import Home from './Components/Home/Home';
import Contactus from './Components/ContactUs/Contactus';
// import Services from './Components/Services/Services';
import MobApp from './Components/Services/MobApp/MobApp';
import DigiMarket from './Components/Services/DigiMarket/DigiMarket';
import WebDev from './Components/Services/WebDev/WebDev';
import Ecommerce from './Components/Services/Ecommerce/Ecommerce';
import Footer from './Components/Home/Footer';
import PageNotFound from './Components/Home/PageNotFound';
import Product from './Components/product/Product';



function App() {
  return (
    <div >

    <Router>
    <Navbar />
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/services/mobile-application" element={<MobApp />}  />
          <Route path="/services/digital-marketing" element={<DigiMarket />} />
          <Route path="/services/web-development" element={<WebDev />}  />
          <Route path="/services/e-commerce" element={<Ecommerce />}  />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="*" element={<PageNotFound />} />
      
      </Routes>

      <Footer />
    </Router>



    
    </div>
  );
}

export default App;



