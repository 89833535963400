
import { 
  FaCheckCircle, 
  FaUserCheck, 
  FaCogs, 
  FaLock, 
  FaChartBar, 
  FaBoxOpen, 
  FaPalette, 
  FaWarehouse, 
  FaUserCircle, 
  FaChartLine,
  FaCalculator,
  FaShoppingCart,
  FaBalanceScale,
  FaHeadset 
} from 'react-icons/fa';



// ProductData.js
const products = [
  {
    title: 'Online Assessment Application',
    description: 'Our Online Assessment Application is a cutting-edge platform tailored for both students and recruiters, designed to streamline the initial interview process. With this application, recruiters can conduct skill-based assessments, while students gain valuable practice through real-world scenarios.',
    features: [
      {
        icon: <FaCheckCircle />,
        title: 'Skill-Based Assessments',
        description: 'Customizable tests and assignments to match specific skill requirements.',
      },
      {
        icon: <FaChartBar />,
        title: 'Real-Time Analytics',
        description: 'Get instant feedback on performance, with detailed insights to help recruiters make data-driven decisions.',
      },
      {
        icon: <FaCogs />,
        title: 'Seamless Integration',
        description: 'Easy integration with other recruitment tools, allowing a smoother workflow.',
      },
      {
        icon: <FaUserCheck />,
        title: 'User-Friendly Interface',
        description: 'A clean, intuitive design for ease of use, making it accessible for all users.',
      },
    ],
    image: `${process.env.PUBLIC_URL}/Images/onlineassessment.webp`,
    onlinedes :'This platform is ideal for universities, recruitment agencies, and companies looking to simplify and elevate the hiring process, especially for the initial stages of candidate evaluation.'
  },
  {
    title: 'Leather E-commerce Application',
    description: 'Our Leather E-commerce Application provides a comprehensive platform for retailers and manufacturers specializing in leather products. Built with scalability and flexibility in mind, this application ensures that your brand’s unique character is represented, while offering a seamless shopping experience to customers.',
    features: [
      {
        icon: <FaBoxOpen />, 
        title: 'Product Management',
        description: 'Effortlessly add, categorize, and manage an extensive catalog of leather goods, from bags and belts to custom items.',
      },
      {
        icon: <FaLock />, 
        title: 'Secure Checkout',
        description: 'Ensure secure transactions with multiple payment options, including credit cards, digital wallets, and COD.',
      },
      {
        icon: <FaPalette />,
        title: 'Customizable UI',
        description: 'Tailor the look and feel of your store with options for colors, fonts, and layouts to reflect your brand identity.',
      },
      {
        icon: <FaWarehouse />, 
        title: 'Real-Time Inventory Tracking',
        description: 'Track stock levels and automate notifications to streamline operations.',
      },
      {
        icon: <FaUserCircle />,
        title: 'Customer Account Management',
        description: 'Enable customers to create accounts, track orders, and access personalized recommendations.',
      },
      {
        icon: <FaChartLine />, 
        title: 'SEO and Analytics Tools',
        description: 'Built-in tools for better search visibility and insights into sales performance.',
      },
    ],
    image: `${process.env.PUBLIC_URL}/Images/e-commerce-development.webp`,
    onlinedes : 'Whether launching a new brand or expanding an existing business, our leather e-commerce application is the perfect solution to grow your online presence.'
  },
  {
    title: 'Extended Warranty Application for Cars',
    description: 'Our Extended Warranty Application for the automotive industry empowers users to get instant quotes for extended warranty plans on their vehicles. Designed to simplify the purchasing process, this application enables users to select a plan that suits their needs and complete the purchase offline, ensuring a hassle-free experience.',
    features: [
      {
        icon: <FaCalculator />,
        title: 'Instant Quote Generation',
        description: 'Easily calculate warranty costs based on car make, model, and age.',
      },
      {
        icon: <FaShoppingCart />,
        title: 'Offline Purchase Flexibility',
        description: 'Users can conveniently generate quotes online and finalize the purchase offline, providing a blend of digital convenience and in-person support.',
      },
      {
        icon: <FaBalanceScale />,
        title: 'Warranty Comparison',
        description: 'Compare various coverage levels and durations to find the ideal plan.',
      },
      {
        icon: <FaHeadset />,
        title: 'Customer Support',
        description: 'Integrated support options for easy query resolution and plan clarification.',
      },
    ],
    image: `${process.env.PUBLIC_URL}/Images/autoprowarranty-img.png`,
    onlinedes : 'Perfect for car dealerships and warranty providers, our application helps ensure customers find peace of mind with comprehensive, customizable warranty coverage options.'
  }
];

export default products;

