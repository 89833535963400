import React from 'react'
import '../../CSS/Home/HomeDigitalInnovation.css'

const DigitalInnovation = () => {
  return (
   <div className="digit-innov-container">
    <div className="digit-innov-wrapper">
        <div className="digit-innov-heading">
            <img src={`${process.env.PUBLIC_URL}/Images/digital-innovation-org1.jpg`} alt="" />

            <div className="digit-innov-main-content">
                <div className="digit-innov-main-heading">
                    <h2>Your Trusted Partner in Digital Innovation</h2>
                    <p className="digit-innov-para">
                    In a rapidly evolving digital world, your business needs a partner who understands technology and can translate it into solutions that drive growth and engagement. At TheLogical Monks, we specialize in transforming ideas into powerful, scalable, and tailored digital solutions. With a focus on Digital Marketing, Web Application Development, Website Development, and E-commerce Solutions, we are here to take your business to the next level.
                    </p>
                </div>
            </div>
        </div>
    </div>
   </div>
  )
}

export default DigitalInnovation