import React from 'react';
import '../../CSS/Home/Home-mission.css';

const MissionStatement = () => {
  return (
    <div className="mission-container">
      <div className="mission-card">
            <img src={`${process.env.PUBLIC_URL}/Images/Mission-homeimg-1.jpg`} alt="E-commerce Application Development" />
            <div className="home-service-card-content">
              <h3 className="mission-heading">Your Success Is Our Mission</h3>
              <p className="home-mission-content">
        We are committed to empowering businesses like yours with high-quality, impactful digital solutions. When you work with theLogicalMonks, you gain a partner dedicated to your growth and success.
      </p>
            </div>
          </div>
    </div>
  );
};

export default MissionStatement;
